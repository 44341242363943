import React from 'react'
import './Contactus.css'

function Contactusheading() {
  return (
    <div className='Contactusheading' >
      <div className='Contactusdesc'>
      <div className='Contactuscontent'>
    <div className='Contactusparagraph' >
    <div className='Contactusdot'></div>
    <p>Contact us</p>
    </div>
      <h1 className='ContactusHeading'>Let's make your brand brilliant!</h1>
    </div>
      </div>
    </div>
  )
}

export default Contactusheading
