import React from 'react'
import './Portfoliopage.css';

function Portfoliopageheading() {
  return (
    <div className='Portfoliopageheading' >
      <div className='Portfoliopagebanner-heading'>
      <div className='Portfoliopage-para'>
      <div className='Portfoliopage-dot'></div> 
      <p>Portfolio</p>
      </div>
<h1>We take pride in your success.</h1>
      
      </div>
    </div>
  )
}

export default Portfoliopageheading
