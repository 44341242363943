import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Clientreview from "./Clientsreview/Clientreview";
import Main from "./Main/Main";
import Animations from "./Animations/Animation";
import ScrollToTopButton from "./Scrolltotop/ScrollToTopButton";
import Techstack from "./Techstack/Techstack";
import Webdevelopment from "./WebDevelopment/Webdevelopment";
import Homeportfolio from "./Homeportfolio/Homeportfolio";
import Bgimage from "./Bgimagesection/Bgimage";
import Blogs from "./Blogs/Blogs";
import Footer from "../../components/Footer/Footer";
import Ourclients from "./Ourclients/Ourclients";
import Ourservice from "./Ourservice/Ourservice";
import { Helmet } from "react-helmet";

function HomepageMain() {
  return (
    <div style={{ background: "#181818" }}>
      <Helmet>
        <title>Dwebbox | Home</title>
        <meta
          name="description"
          content="What began as a hustle by two bold and young engineering graduates has now blossomed into full-fledged pan-India team united by the mission of enabling your digital transformation and connecting you to your customer !"
        />
        <meta
          name="keywords"
          content="react, website, app, dwebbox, dream, design, digitize,portfolio, portfolio website, ecommerce, ecommerce website"
        />
      </Helmet>

      <ScrollToTopButton />
      <Navbar />
      <Main />
      <Animations />
      <Homeportfolio />
      <Ourservice />

      <Webdevelopment />

      <Ourclients />
      <Clientreview />
      <Techstack />
      <Blogs />
      <Bgimage />
      <Footer />
    </div>
  );
}

export default HomepageMain;
