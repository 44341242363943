import React from "react";
import "./Clientspage.css";
import Clients1 from "../../Assets/Images/Clients/Group 18160.png";
import Clients2 from "../../Assets/Images/Clients/Group 18161.png";
import Clients3 from "../../Assets/Images/Clients/Group 18401.png";
import Clients4 from "../../Assets/Images/Clients/Group 18402.png";
import Clients5 from "../../Assets/Images/Clients/Group 18403.png";
import Clients6 from "../../Assets/Images/Clients/Group 18406.png";
import Clients7 from "../../Assets/Images/Clients/Group 18407.png";
import Clients8 from "../../Assets/Images/Clients/Group 18408.png";
import Clients9 from "../../Assets/Images/Clients/Group 18412-1.png";
import Clients10 from "../../Assets/Images/Clients/Group 18412.png";
import Clients11 from "../../Assets/Images/Clients/Group 18413-1.png";
import Clients12 from "../../Assets/Images/Clients/Group 18413.png";
import Clients13 from "../../Assets/Images/Clients/Group 18414-1.png";
import Clients14 from "../../Assets/Images/Clients/Group 18414.png";
import Clients15 from "../../Assets/Images/Clients/Group 18415-1.png";
import Clients16 from "../../Assets/Images/Clients/Group 18415.png";
import Clients17 from "../../Assets/Images/Clients/Group 18416.png";
import Clients18 from "../../Assets/Images/Clients/Group 18417.png";
import Clients19 from "../../Assets/Images/Clients/Group 18418.png";
import Clients20 from "../../Assets/Images/Clients/Group 18419.png";
import Clients21 from "../../Assets/Images/Clients/Group 18420.png";
import Clients22 from "../../Assets/Images/Clients/Group 18421.png";
import Clients23 from "../../Assets/Images/Clients/Group 18425.png";
import Clients24 from "../../Assets/Images/Clients/Group 18426.png";
import Clients25 from "../../Assets/Images/Clients/Group 18440.png";
import Clients26 from "../../Assets/Images/Clients/Group 18441.png";
import Clients27 from "../../Assets/Images/Clients/Group 18442.png";
import Clients28 from "../../Assets/Images/Clients/Group 18443.png";
import Clients29 from "../../Assets/Images/Clients/Group 18445.png";
import Clients30 from "../../Assets/Images/Clients/Group 18450.png";

import Clients31 from "../../Assets/Images/Clients/Group 1272628229.png";
import Clients32 from "../../Assets/Images/Clients/Group 1272628230.png";
import Clients33 from "../../Assets/Images/Clients/Group 1272628233.png";
import Clients34 from "../../Assets/Images/Clients/Group 1272628235.png";
import Clients35 from "../../Assets/Images/Clients/Group 1272628236.png";
import Clients36 from "../../Assets/Images/Clients/Group 1272628239.png";
import Clients37 from "../../Assets/Images/Clients/Group 1272628241.png";
import Clients38 from "../../Assets/Images/Clients/Group 1272628244.png";
import Clients39 from "../../Assets/Images/Clients/Group 1272628247.png";
import Clients40 from "../../Assets/Images/Clients/Group 1272628248.png";
import Clients41 from "../../Assets/Images/Clients/Group 1272628252.png";
import Clients42 from "../../Assets/Images/Clients/Group 1272628255.png";
import Clients43 from "../../Assets/Images/Clients/Group 1272628256.png";
import Clients44 from "../../Assets/Images/Clients/Group 1272628257.png";
import Clients45 from "../../Assets/Images/Clients/Group 1272628258.png";
import Clients46 from "../../Assets/Images/Clients/Group 1272628259.png";
import Clients47 from "../../Assets/Images/Clients/Group 1272628260.png";
import Clients48 from "../../Assets/Images/Clients/Group 1272628261.png";
import Clients49 from "../../Assets/Images/Clients/Group 1272628263.png";
import Clients50 from "../../Assets/Images/Clients/Group 1272628266.png";
import Clients51 from "../../Assets/Images/Clients/Group 1272628267.png";
import Clients52 from "../../Assets/Images/Clients/Group 1272628268.png";
import Clients53 from "../../Assets/Images/Clients/Group 1272628270.png";
import Clients54 from "../../Assets/Images/Clients/Group 1272628272.png";
import Clients55 from "../../Assets/Images/Clients/Group 1272628274.png";
import Clients56 from "../../Assets/Images/Clients/Group 1272628276.png";
import Clients57 from "../../Assets/Images/Clients/Group 1272628278.png";
import Clients58 from "../../Assets/Images/Clients/Group 1272628280.png";
import Clients59 from "../../Assets/Images/Clients/Group 1272628282.png";
import Clients60 from "../../Assets/Images/Clients/Group 1272628284.png";
import Clients61 from "../../Assets/Images/Clients/Group 1272628286.png";
import Clients62 from "../../Assets/Images/Clients/Group 1272628288.png";
import Clients63 from "../../Assets/Images/Clients/Group 1272628290.png";
import Clients64 from "../../Assets/Images/Clients/Group 1272628293.png";
import Clients65 from "../../Assets/Images/Clients/Group 1272628344.png";
import Clients66 from "../../Assets/Images/Clients/Group 18477.png";

function Clientslistpage() {
  return (
    <div className="Clientslistpage">
      <div className="clientsimagesection">
        <div className="clientsimagesection-imgBox">
          <img src={Clients17} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients18} alt="" />
        </div>

        <div className="clientsimagesection-imgBox">
          <img src={Clients14} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients10} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients2} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients3} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients1} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients4} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients5} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients6} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients7} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients8} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients9} alt="" />
        </div>

        <div className="clientsimagesection-imgBox">
          <img src={Clients11} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients12} alt="" />
        </div>

        <div className="clientsimagesection-imgBox">
          <img src={Clients13} alt="" />
        </div>

        <div className="clientsimagesection-imgBox">
          <img src={Clients15} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients16} alt="" />
        </div>

        <div className="clientsimagesection-imgBox">
          <img src={Clients19} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients20} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients21} alt="" />
        </div>
        {/* <div className='clientsimagesection-imgBox' >
    <img src={Clients22} alt=""  />
    </div> */}
        <div className="clientsimagesection-imgBox">
          <img src={Clients23} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients24} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients25} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients26} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients27} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients28} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients29} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients30} alt="" />
        </div>
        {/* <div className='clientsimagesection-imgBox' >
    <img src={Clients31} alt=""  />
    </div>
    <div className='clientsimagesection-imgBox' >
    <img src={Clients32} alt=""  />
    </div> */}
        <div className="clientsimagesection-imgBox">
          <img src={Clients33} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients34} alt="" />
        </div>
        {/* <div className='clientsimagesection-imgBox' >
    <img src={Clients35} alt=""  />
    </div> */}
        <div className="clientsimagesection-imgBox">
          <img src={Clients36} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients37} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients38} alt="" />
        </div>
        {/* <div className='clientsimagesection-imgBox' >
    <img src={Clients39} alt=""  />
    </div> */}
        {/* <div className='clientsimagesection-imgBox' >
    <img src={Clients40} alt=""  />
    </div> */}
        <div className="clientsimagesection-imgBox">
          <img src={Clients41} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients42} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients43} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients44} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients45} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients46} alt="" />
        </div>
        {/* <div className='clientsimagesection-imgBox' >
    <img src={Clients47} alt=""  />
    </div> */}
        <div className="clientsimagesection-imgBox">
          <img src={Clients48} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients49} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients50} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients51} alt="" />
        </div>
        {/* <div className="clientsimagesection-imgBox">
          <img src={Clients52} alt="" />
        </div> */}
        <div className="clientsimagesection-imgBox">
          <img src={Clients53} alt="" />
        </div>
        {/* <div className="clientsimagesection-imgBox">
          <img src={Clients54} alt="" />
        </div> */}
        <div className="clientsimagesection-imgBox">
          <img src={Clients55} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients56} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients57} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients58} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients59} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients60} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients61} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients62} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients63} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients64} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients65} alt="" />
        </div>
        <div className="clientsimagesection-imgBox">
          <img src={Clients66} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Clientslistpage;
