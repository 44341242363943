import { Box, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import imgone from '../../../src/Assets/Images/AboutUsBgImage/Rectangle 47.png'
import imgtwo from '../../../src/Assets/Images/AboutUsBgImage/karankushalimage.png'
import CountUp from 'react-countup';
import { animateScroll as scroll } from 'react-scroll';



function Aboutusjourney() {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1400 && !isVisible) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  return (
    <div>
      <Box sx={{ width: "100%", background: "#181818" }} >
        <Container component="main" sx={{
        pt: 8,
        pb:8, 
        background: "#181818",
        display:"flex",
        justifyContent:"space-between",
        '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                flexDirection:"column-reverse",
                paddingLeft:"32px",
                paddingRight:"0px",
                

              },
        }}>
           <Box sx={{
            width:"40%",
            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            width:"100%"
              },
           
           }} >
             <Box>
             <img src={imgtwo} alt=""  width="90%"/>
             </Box>
             <Typography paragraph 
             sx={{
              marginTop:"15vh",
              fontSize:"28px",
              color:"#ffffff",
              fontFamily:"DM Sans_18pt-ExtraLight",
              lineHeight:"42px",
              '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
              fontSize:"14px",
              lineHeight:"20px",
              marginTop:"5vh",
              width:"90%",

              },
             }}
             >
             We value open communication and would love to hear from you. Whether you have a question, a project idea, or simply want to learn more about our services, our team at D-WebBox is here to assist you.
             </Typography>
      <Box sx={{background:"#000",
      width:"100%",
      height:"120vh",
      marginTop:"12vh",
      paddingTop:"5vh",
                            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
height:"70vh",
width:"90%",

                            }
      
      }} >
 <Typography variant='h6' sx={{color:"#ffffff",
 textAlign:"center",
 fontFamily: "DM Sans_18pt-ExtraLight",
 fontSize:"30px",
 fontWeight:"400",
 '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
    fontSize:"20px",
  }
 
  }}
  > What we have achieved so far.... </Typography>
 <Box sx={{width:"90%",
 height:"60%",
 margin:"10vh auto",
 display:"flex",
 flexWrap:"wrap",
 justifyContent:"space-between"
  
 }} >
   <Box sx={{ width: "45%", height: "35%", background: "black", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant='h3' sx={{ color: "rgba(241, 124, 36, 1)", fontWeight: "700", fontFamily: "DM Sans18ptLight" }}>
                    {isVisible && <CountUp end={100} duration={3} />}
                    {isVisible && <span>+</span>}
                  </Typography>
                  <Typography paragraph sx={{ color: "white", fontSize: "28px", fontFamily: "DM Sans_18pt-ExtraLight" }}>Clients</Typography>
                </Box>

                <Box sx={{ width: "45%", height: "35%", background: "black", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant='h3' sx={{ color: "rgba(241, 124, 36, 1)", fontWeight: "700", fontFamily: "DM Sans18ptLight" }}>
                    {isVisible && <CountUp end={5} duration={3} />}
                
                  </Typography>
                  <Typography paragraph sx={{ color: "white", fontSize: "28px", fontFamily: "DM Sans_18pt-ExtraLight" }}>Countries</Typography>
                </Box>
                <Box sx={{ width: "45%", height: "35%", background: "black", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant='h3' sx={{ color: "rgba(241, 124, 36, 1)", fontWeight: "700", fontFamily: "DM Sans18ptLight" }}>
                    {isVisible && <CountUp end={12} duration={3} />}
                    {isVisible && <span>+</span>}
                  </Typography>
                  <Typography paragraph sx={{ color: "white", fontSize: "28px", fontFamily: "DM Sans_18pt-ExtraLight" }}>Industries</Typography>
                </Box>
                <Box sx={{ width: "45%", height: "35%", background: "black", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant='h3' sx={{ color: "rgba(241, 124, 36, 1)", fontWeight: "700", fontFamily: "DM Sans18ptLight" }}>
                    {isVisible && <CountUp end={5} duration={3} />}
                    {isVisible && <span>+</span>}
                  </Typography>
                  <Typography paragraph sx={{ color: "white", fontSize: "28px", fontFamily: "DM Sans_18pt-ExtraLight" }}>Years</Typography>
                </Box>

                <Box sx={{
                margin:"0 auto",  
                display:"flex",
                flexDirection:"column",
                justifyContent:"center",
                alignItems:"center"
    
        }} >
                  <Typography variant='h4' sx={{
                    marginTop:"10vh",
                    color:"#ffffff",
                    fontFamily: "DM Sans_18pt-ExtraLight",
                  fontSize:"30px",
                  '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
    fontSize:"20px",
  }
                  }} >
                  And still counting to reach to 
                  </Typography>
                  <Typography variant='h6' sx={{color:"#ffffff",
                  fontSize:"32px",
                  fontWeight:"700"
    
                  }} >
                  The Mars
                  </Typography>
                </Box>

 </Box>
</Box>
           </Box>



           <Box sx={{
            width:"49%",
            '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
            width:"100%"
              },
           }} >
           <Box>
              <img src={imgone} alt=""  width="90%"/>
             </Box>
             <Typography paragraph
            sx={{
              marginTop:"15vh",
              fontSize:"28px",
              color:"#ffffff",
              fontFamily:"DM Sans_18pt-ExtraLight",
              lineHeight:"42px",
              '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
            fontSize:"14px",
            lineHeight:"20px",
            marginTop:"5vh",
            width:"95%",

              },
             }}
             >
             By unlocking boundless potential, we mean harnessing the collective power of our clients, partners, and team members. We understand that true greatness is achieved when diverse perspectives, expertise, and talents converge.
             </Typography>
             <Typography
             
             paragraph
            sx={{
              marginTop:"5vh",
              fontSize:"28px",
              color:"#ffffff",
              fontFamily:"DM Sans_18pt-ExtraLight",
              lineHeight:"42px",
              '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
            fontSize:"14px",
            lineHeight:"20px",
            marginTop:"2vh",
            width:"90%",

              },
             }}

             >
Together, we embark on a transformative journey, breaking through limitations and exploring uncharted territories. We encourage exploration, experimentation, and taking calculated risks.
             </Typography>
             <Typography 
             
             paragraph
            sx={{
              marginTop:"5vh",
              fontSize:"28px",
              color:"#ffffff",
              fontFamily:"DM Sans_18pt-ExtraLight",
              lineHeight:"42px",
              '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
            fontSize:"14px",
            lineHeight:"20px",
            marginTop:"2vh",
            marginBottom:"10vh",
            width:"90%",

              },
             }}

             >
It signifies our unwavering dedication to empowering our clients, partners, and team members, and embracing the limitless possibilities that arise when we unite our collective strengths and expertise.
             </Typography>
</Box>
        </Container>
      </Box>
    </div>
  )
}

export default Aboutusjourney
