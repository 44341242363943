import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import video from "../../../Assets/Video/Dwebbox.mp4";
import "./Main.css";

function Main() {
  return (
    <Grid container className="main-dev">
      {/* Left Section */}
      <Grid item xs={12} md={6} className="main-right">
        {/* <div className="heading-section">
          <Typography
            sx={{
              fontFamily: "Epilogue",
              fontWeight: "800",
            }}
            variant="h1"
            className="heading-one"
          >
            Dream 
          </Typography>
          <Typography variant="h1" className="heading-two">
          Design
          </Typography>
          <Typography
            variant="h1"
            sx={{ fontFamily: "Epilogue" }}
            className="heading-three"
          >
            Digitize
          </Typography>
        </div> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
            justifyContent: "center",
            px: {md:4,xs:2},
          }}
        >
          <Typography sx={{...styles.headingText}}>Dream</Typography>
          <Typography sx={{...styles.headingText,color:"#FF9F00",alignSelf:'center'}}>Design</Typography>
          <Typography sx={{...styles.headingText, alignSelf:'flex-end'}}>Digitize</Typography>
        </Box>
      </Grid>
      {/* Right Section */}
      <Grid item xs={12} md={6} className="main-left">
        <div className="video-section">
          <div className="main-video-wrapper"></div>
          <video
            className="dwb-video"
            src={video}
            width="100%"
            autoPlay
            loop
            muted
            playsInline
          ></video>
        </div>
      </Grid>
    </Grid>
  );
}

const styles = {
  headingText: {
    color: "#FFFFFF",
    fontFamily: "Epilogue",
    fontSize: {sm:"7rem",xs:'3rem'},
    lineHeight: {sm:"9rem",xs:""},
    fontWeight:'700'
  },
};
export default Main;
