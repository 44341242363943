import React, { useEffect } from 'react';
import './Preloader.css'
import { preLoaderAnim } from '../../animations';
function Preloader() {

    useEffect(() => {
      window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts

        preLoaderAnim()
    },[]);


  return (
    <div className='preloader'>
      <div className='texts-container'>
       <span className='Dream' >Dream.</span>
       <span className='Design' >Design.</span>
       <span className='Digitize' >Digitize.</span>
      </div>
    </div>
  )
}

export default Preloader
