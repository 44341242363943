import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import ScrollToTopButton from '../HomePage/Scrolltotop/ScrollToTopButton'
import Bgimage from '../HomePage/Bgimagesection/Bgimage'
import Footer from '../../components/Footer/Footer'
import { Box, Card, CardMedia, Container, Typography } from '@mui/material'
import BlogImageBigOne from '../../Assets/Images/BlogsImage/pexels-julia-m-cameron-4145190 1.png'
import BlogImagesmallOne from '../../Assets/Images/BlogsImage/pexels-fauxels-3184431 1.png'
import BlogImagesmallTwo from '../../Assets/Images/BlogsImage/cupimage.png'


function Ourblogspagetwo() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
      }, []);

  return (
    <div>
    <ScrollToTopButton/>
    <Navbar/>
    <Box sx={{ width: "100%", background: "#181818" }} >
        <Container component="main" sx={{ pt: 10, background: "#181818"}}>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",
          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                background: "white",
                marginRight:"5px",
              }}
            ></span> Blogs {/* Add a span with the bullet class */}
          </Typography>
          <Typography variant="h1"
           sx={{ color: "#ffffff", 
          display: 'flex', 
          alignItems: 'center',
          fontSize:"55px",
          fontFamily:"Epilogue",
          fontWeight:"600" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '24px',
              },
          }}>
Nurturing Positivity: Maintaining a Positive Work Culture in a Remote Environment
          </Typography>
        </Container>
      </Box>
    

      <Box sx={{ width: "100%", background: "#181818" }} >
        <Container component="main" sx={{ pt: 10,pb:10, background: "#181818"}}>
        <Box>
            <img src={BlogImageBigOne} width= "100%" alt="" />
          </Box>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          marginTop:"5vh",
          fontSize:"20px",
          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
                       {/* <span style={{ fontSize: ' 280%', fontWeight:"700", lineHeight: '30px',padding:"2px 3px",float:"left",marginTop:"3vh",color:"#F17C24"}}></span> */}
                       In recent years, the landscape of work has undergone a significant transformation, with remote work becoming more prevalent than ever. While this shift has brought about numerous benefits such as increased flexibility and reduced commuting stress, it has also presented challenges in maintaining a positive work culture. A positive work culture is crucial for employee morale, productivity, and overall well-being. In this blog, we'll explore strategies to nurture positivity and foster a thriving work culture in a remote setting.

          </Typography>
         

        



   <Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
1.	Communication is Key
          </Typography>

          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
Clear and consistent communication is the cornerstone of a positive remote work culture.
 Encourage open dialogue among team members through regular team meetings, one-on-one check-ins, 
 and collaborative platforms. Emphasize the importance of active listening and constructive feedback
  to ensure everyone feels heard and valued.         
   </Typography>
   <Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
2.	Set Clear Expectations
          </Typography>

          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
 Define roles, responsibilities, and goals clearly to avoid confusion and promote accountability. Establish realistic timelines and milestones, and encourage team members to communicate any challenges or roadblocks they encounter. Clarity breeds confidence and reduces unnecessary stress.
      
   </Typography>
   <Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
3.	Encourage Flexibility and Work-Life Balance
          </Typography>

          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
Remote work offers the flexibility to balance professional and personal commitments. Encourage your team to establish boundaries between work hours and personal time. Promote self-care practices and provide resources for mental health support. A well-rested and balanced team is more likely to contribute positively to the work culture.
       
   </Typography>
   <Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
4.	Promote Team Bonding and Social Interaction
          </Typography>

          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
Foster a sense of camaraderie and belonging through virtual team-building activities, casual chats, and shared experiences. Celebrate achievements, milestones, and birthdays to create a positive and inclusive environment. Encourage virtual coffee breaks or social gatherings to strengthen interpersonal connections.
       
   </Typography>
   <Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
5.	Emphasize Appreciation and Recognition
          </Typography>

          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
Recognize and appreciate the efforts and achievements of your team members regularly. A simple "thank you" or acknowledgment of a job well done goes a long way in boosting morale and motivation. Consider implementing a recognition program or awards to highlight exceptional contributions.
        
   </Typography>

          <Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
6.	Invest in Professional Development
          </Typography>

          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
Support continuous learning and growth by providing opportunities for training, skill development, and career advancement. Encourage knowledge sharing and mentorship within the team. A culture that values learning fosters innovation and adaptability.
          </Typography>
        
          <Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
7.	Lead by Example
          </Typography>

          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
As a leader or manager, embody the values and behaviors you wish to see in your team. Demonstrate empathy, transparency, and resilience in your interactions. Encourage a positive mindset and proactively address any issues or conflicts that arise.
          </Typography>

          <Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
8.	Seek Feedback and Iterate
          </Typography>

          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
Regularly seek feedback from your team on the work culture, processes, and areas for improvement. Use this input to make necessary adjustments and iterate on existing practices. Involve team members in decision-making whenever possible to foster a sense of ownership and empowerment.
          </Typography>
         
<Box sx={{height:"90vh",width:"100%", display:"flex",marginTop:"10vh",justifyContent:"space-between",flexWrap:"wrap"}} >
<Card sx={{ maxWidth: 560,borderRadius:0,background:"#181818",boxShadow: 'none',}}>
              <CardMedia
                component="img"
                image={BlogImagesmallOne}
                alt="green iguana"
              />
            </Card>
            <Card sx={{ maxWidth: 560,borderRadius:0,boxShadow: 'none',background:"#181818" }}>
              <CardMedia
                component="img"
                image={BlogImagesmallTwo}
                alt="green iguana"
              />
            </Card>
</Box>



          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
In conclusion, maintaining a positive work culture in a remote environment requires intentional effort, effective communication, and a focus on employee well-being. By prioritizing transparency, collaboration, appreciation, and continuous improvement, organizations can create a thriving remote work culture where employees feel motivated, engaged, and connected despite geographical distances.     
    </Typography>

        </Container>
      </Box>

    <Bgimage/>
    <Footer/>
    </div>
  )
}

export default Ourblogspagetwo
