import React from 'react';
import './Contactus.css'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Button } from '@mui/material';


function Workwithus() {
  return (
    <div className='Workwithus'>
      <div className='workwithusbannersection' >
       <div className='workwithusinfoheading' >
        <h1>Work with us</h1>
        <p>Our origins intertwine with Mumbai, the bustling heart of India's commerce and a haven of vibrant life. Through the passage of time, most of our dedicated employees have embarked on a quest to rediscover our rich heritage.</p>
       </div>
       <div className='job-roles' >
          <div className='job-role-heading' >
          <h1>Open job roles</h1>
          </div>
          <div className='job-role-section' >
            <div className='job-section' >
<h2>Digital Sales Associate</h2>
<p>We are seeking a dynamic and results-driven Digital Sales Associate.</p>
<Button
            variant="outlined"
            sx={{
              background:"#FFFFFF",
              border:"none",
              marginTop:"2vh",
              color: 'black',
              width: '120px',
              height: '32px',
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            Mumbai
          </Button>
          <div>
          <Button
            variant="outlined"
            sx={{
              border: '1.2px solid white',
              color: 'white',
              marginTop:'3vh',
              width: '250px',
              height: '45px',
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            View Job Description <ArrowOutwardIcon />
          </Button>
          </div>
            </div>
            <div className='job-section' >
<h2>Full Stack Developer</h2>
<p>We are looking for a MERN stack developer interested in building performant web and mobile apps.</p>
<Button
            variant="outlined"
            sx={{
              background:"#FFFFFF",
              border:"none",
              color: 'black',
              width: '120px',
              height: '32px',
              marginTop:"2vh",
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            Mumbai
          </Button>
          <div>
          <Button
            variant="outlined"
            sx={{
              border: '1.2px solid white',
              color: 'white',
              width: '250px',
              height: '45px',
              marginTop:'3vh',
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            View Job Description <ArrowOutwardIcon />
          </Button>
          </div>

</div>
<div className='job-section' >
<h2>Backend Developer</h2>
<p>Knowledge sharing with the broader development ecosystem.</p>
<Button
            variant="outlined"
            sx={{
              background:"#FFFFFF",
              border:"none",
              color: 'black',
              width: '120px',
              height: '32px',
              marginTop:"2vh",
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            Mumbai
          </Button>
          <div>
          <Button
            variant="outlined"
            sx={{
              border: '1.2px solid white',
              color: 'white',
              width: '250px',
              height: '45px',
              marginTop:'3vh',
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            View Job Description <ArrowOutwardIcon />
          </Button>
          </div>

</div>
<div className='job-section' >
<h2>Front-End Web Develope</h2>
<p>We are looking for a Front-End Web Developer who is motivated to combine the art of design with the art of programming.</p>
<Button
            variant="outlined"
            sx={{
              background:"#FFFFFF",
              border:"none",
              color: 'black',
              width: '120px',
              height: '32px',
              marginTop:"2vh",
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            Mumbai
          </Button>
          <div>
          <Button
            variant="outlined"
            sx={{
              border: '1.2px solid white',
              color: 'white',
              width: '250px',
              height: '45px',
              marginTop:'3vh',
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            View Job Description <ArrowOutwardIcon />
          </Button>
          </div>

</div>
<div className='job-section' >
<h2>React Native developer</h2>
<p>We are looking for a React Native developer interested in building performant mobile apps on both the iOS and Android platforms.</p>
<Button
            variant="outlined"
            sx={{
              background:"#FFFFFF",
              border:"none",
              color: 'black',
              width: '120px',
              height: '32px',
              marginTop:"2vh",
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            Mumbai
          </Button>
          <div>
          <Button
            variant="outlined"
            sx={{
              border: '1.2px solid white',
              color: 'white',
              width: '250px',
              height: '45px',
              marginTop:'3vh',
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            View Job Description <ArrowOutwardIcon />
          </Button>
          </div>

</div>
<div className='job-section' >
<h2>Client Servicing Executive</h2>
<p>We are seeking a highly motivated and experienced Software Project Manager to join our dynamic IT team.</p>
<Button
            variant="outlined"
            sx={{
              background:"#FFFFFF",
              border:"none",
              color: 'black',
              width: '120px',
              height: '32px',
              marginTop:"2vh",
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            Mumbai
          </Button>
          <div>
          <Button
            variant="outlined"
            sx={{
              border: '1.2px solid white',
              color: 'white',
              width: '250px',
              height: '45px',
              marginTop:'3vh',
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#fff',
                color:"#000",
                border:"#fff" // Set the hover background color to transparent
              },
            }}
          >
            View Job Description <ArrowOutwardIcon />
          </Button>
          </div>

</div>
          </div>
       </div>
      </div>
    </div>
  )
}

export default Workwithus
