import React from 'react'
import video from '../../Assets/Video/Myvedio.mp4';
import ReactPlayer from 'react-player';



function Aboutusbgbanner() {
  return (
    <div>
      <div className='Aboutusbgimage' >
      <div className='AboutUsBgimageDescription'>
        <p> <span className='WeDream' > We Dream. </span> <span className='WeDesign' >We Design.</span>   <span className='WeDigitize' > We Digitize.</span> </p>
        <h1>We are D<span>-</span>WebBox</h1>
      </div>
      <video src={video} className='about-us-video'  preload='auto'  autoPlay loop muted playsInline >
        
      </video>
      </div>
    </div>
  )
}

export default Aboutusbgbanner
