import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Bgimage from '../HomePage/Bgimagesection/Bgimage';
import { Box, Container, Typography } from '@mui/material';
import MobileIDlabs from '../../../src/Assets/Images/PortfolioImages/midbanner.webp'
import MobileIdlabscasestudy from '../../../src/Assets/Images/PortfolioImages/Mid_1x.webp'

function Mobileidlabscasestudy() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
      }, []);

  return (
    <div>
      <div style={{background:"#181818"}} >
    <Navbar/>
    <Box sx={{ width: "100%", background: "#181818" }} >
        <Container component="main" sx={{ pt: 10, 
        background: "#181818", 
         '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
         paddingTop:"40px",
              },}}>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",
          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                background: "white",
                marginRight:"5px",
              }}
            ></span> Portfolio {/* Add a span with the bullet class */}
          </Typography>
          <Typography variant="h1"
           sx={{ color: "#ffffff", 
          display: 'flex', 
          alignItems: 'center',
          fontSize:"70px",
          fontFamily:"Epilogue",
          fontWeight:"600" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '24px',
              },
          }}>
        Mobile ID Lab
          </Typography>
        </Container>
    </Box>
    <Box sx={{ width: "100%", background: "#181818" }} >
        <Container  component="main" sx={{ 
          pt: 10,
        pb:10, 
        background: "#181818",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            pt:5,
            pb:5,

         }
        
        }}>
        <Box>
            <img src={MobileIDlabs} width= "100%" alt="" />
          </Box>
         
         <Box sx={{display:"flex",
          alignItems:"center", 
          width:"100%",
          background:"rgba(34, 34, 34, 1)",
          height:"20vh",
          marginTop:"20vh",
          '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          flexDirection:"column",
                          height:"30vh",
                          marginTop:"5vh",
                          
              },
          
          }} >
           <Box sx={{display:"flex",
           flexDirection:"column",
           alignItems:"center", 
           width:"30%",
           color:"rgba(255, 255, 255, 1)",
           '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            width:"60%",
            marginTop:"5%",
            height:"33%"

                          
              },
           
           }} >
           <Typography variant='h5'sx={{
             '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
         Industry
          </Typography>
          <Typography paragraph sx={{fontWeight:"300",fontSize:"20px",
                      fontFamily: "DM Sans18ptLight",

            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
         Healthcare
          </Typography>
           </Box>
           <Box sx={{display:"flex",
           flexDirection:"column",
           alignItems:"center", 
           width:"40%",
           color:"rgba(255, 255, 255, 1)",

           '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          width:"60%",
                          
              },
           }} >
          <Typography variant='h5'sx={{
                        fontFamily: "DM Sans18ptLight",

             '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
          Services Provided
          </Typography>
          <Typography paragraph sx={{fontWeight:"300",fontSize:"20px",
                      fontFamily: "DM Sans18ptLight",

            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"12px",
                          
              },
          }} >
          Web & App Development
          </Typography>
           </Box>
           <Box sx={{display:"flex",flexDirection:"column",alignItems:"center", width:"30%",color:"rgba(255, 255, 255, 1)"}} >
           <Typography variant='h5'sx={{
             '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
          Year
          </Typography>
          <Typography paragraph sx={{fontWeight:"300",fontSize:"20px",
                      fontFamily: "DM Sans18ptLight",

            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"12px",
                          
              },
          }} >
         2021-2022
          </Typography>
           </Box>
         </Box>
         
<Box sx={{background:"rgba(34, 34, 34, 1)",
padding:"5vh",
marginTop:"10vh",
'@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
            padding:"3vh",
            marginTop:"5vh",


          }

}} >
<Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
The Challenge        
  </Typography>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
Can we create a digital platform where CardServ can onboard customers who want to create digital IDs?
Can we create a simple and easy to use functionality for customers to design their ID cards?
Can we automatically generate all the ID cards after finalizing the design by feeding the employee data into the system?
Can we provide a comprehensive list of security features associated with the ID card? make these points into paragraph     
      </Typography>
</Box>
          
          <Box sx={{background:"rgba(34, 34, 34, 1)",
          padding:"5vh",
          marginTop:"10vh",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
            padding:"3vh",
            marginTop:"5vh",
          }
          
          }} >
<Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
Approach
  </Typography>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
We introduced a cutting-edge digital platform tailored for seamless customer onboarding seeking to establish digital IDs. Our user-friendly interface simplifies the design process, enabling customers to effortlessly craft personalized ID cards. Upon finalizing designs, our system automates ID card generation by seamlessly integrating employee data. Additionally, CardServ offers a comprehensive array of security features, ensuring the utmost protection for digital IDs. From biometric encryption to barcode integration, our platform guarantees the highest standards of security, instilling confidence in both customers and end-users.
  </Typography>
</Box>
         

        
         

        </Container>
      </Box>
      <Box sx={{background:"#181818"}} >
        <img src={MobileIdlabscasestudy} alt="" width="100%" />
      </Box>
    <Bgimage/>
    <Footer/>  
    </div>
    </div>
  )
}

export default Mobileidlabscasestudy
