import React, { useEffect } from 'react';

import Navbar from '../../components/Navbar/Navbar';
import { Box, Container, Typography } from '@mui/material';
import Bgimage from '../HomePage/Bgimagesection/Bgimage';
import Footer from '../../components/Footer/Footer';
import GlidinCaseStudy from '../../../src/Assets/Images/PortfolioImages/glidin_1x.webp'
import GlidinCaseBanner from '../../../src/Assets/Images/PortfolioImages/Glidinbanner.webp'


function Glidincasestudy() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
      }, []);


  return (
    <div>
       <div style={{background:"#181818"}} >
    <Navbar/>
    <Box sx={{ width: "100%", background: "#181818" }} >
        <Container component="main" sx={{ pt: 10, 
        background: "#181818", 
         '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
         paddingTop:"40px",
              },}}>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",
          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                background: "white",
                marginRight:"5px",
              }}
            ></span> Portfolio {/* Add a span with the bullet class */}
          </Typography>
          <Typography variant="h1"
           sx={{ color: "#ffffff", 
          display: 'flex', 
          alignItems: 'center',
          fontSize:"70px",
          fontFamily:"Epilogue",
          fontWeight:"600" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '24px',
              },
          }}>
       Glidin
          </Typography>
        </Container>
    </Box>
    <Box sx={{ width: "100%", background: "#181818" }} >
        <Container  component="main" sx={{ 
          pt: 10,
          pb:10, 
          background: "#181818",
          '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            pt:5,
            pb:5,

         }
          }}>
        <Box>
            <img src={GlidinCaseBanner} width= "100%" alt="" />
          </Box>
         
         <Box sx={{display:"flex", 
         alignItems:"center", 
         width:"100%",
         background:"rgba(34, 34, 34, 1)",
         height:"20vh",
         marginTop:"20vh",
         '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          flexDirection:"column",
                          height:"30vh",
                          marginTop:"5vh",
                          
              },
         }} >
           <Box sx={{
            display:"flex",
           flexDirection:"column",
           alignItems:"center", 
           width:"30%",
           color:"rgba(255, 255, 255, 1)",
           '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            width:"60%",
            marginTop:"5%",
            height:"33%"

                          
              },
           }} >
           <Typography variant='h5'sx={{
                        fontFamily: "DM Sans18ptLight",

             '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"16px",
                          
              },
          }} >
         Industry
          </Typography>
          <Typography paragraph sx={{fontWeight:"300",fontSize:"20px",
                      fontFamily: "DM Sans18ptLight",

            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
         Ed-Tech
          </Typography>
           </Box>
           <Box sx={{display:"flex",flexDirection:"column",
           alignItems:"center", width:"40%",
           color:"rgba(255, 255, 255, 1)",
           '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          width:"60%",
                          
              },

           }} >
          <Typography variant='h5'sx={{
                        fontFamily: "DM Sans18ptLight",

             '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"16px",
                          
              },
          }} >
          Services Provided
          </Typography>
          <Typography paragraph sx={{fontWeight:"300",fontSize:"20px",
                      fontFamily: "DM Sans18ptLight",

            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
          Web & App Development
          </Typography>
           </Box>
           <Box sx={{display:"flex",
           flexDirection:"column",alignItems:"center",
            width:"30%",
            color:"rgba(255, 255, 255, 1)",
            
            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
              width:"60%",
                          
              },
            }} >
           <Typography variant='h5'sx={{
                        fontFamily: "DM Sans18ptLight",

             '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"16px",
                          
              },
          }} >
          Year
          </Typography>
          <Typography paragraph sx={{fontWeight:"300",fontSize:"20px",
                      fontFamily: "DM Sans18ptLight",

            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
          2021-2022
          </Typography>
           </Box>
         </Box>
         
<Box sx={{
  background:"rgba(34, 34, 34, 1)",
padding:"5vh",
marginTop:"10vh",
'@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                padding:"3vh",
                marginTop:"5vh",
              },

}} >
<Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
The Challenge        
  </Typography>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
How do we keep the students engaged in the Glidin ecosystem?
Can we reduce the need for students to use multiple platforms for different activities related to studies, such as assignments and classwork?
How can we ensure the student stays interested in the chosen subjects?
For teachers, can we create a feature to address all the students from a branch or section at once?
Can we incorporate extracurricular events on the platform?
How can we incorporate a testing feature on the platform for students to progress from one level to another?
Can we develop a college portal for the administrative staff to engage with teachers and students?
           </Typography>
</Box>
          
          <Box sx={{background:"rgba(34, 34, 34, 1)",padding:"5vh",marginTop:"10vh"}} >
<Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
Approach
  </Typography>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
Our approach was to revolutionizes academic management by providing a singular platform for students to access assignments, lectures, and discussions, eliminating the need for multiple tools. Its personalized learning features, including adaptive algorithms and progress tracking, ensure engagement by catering to individual interests and learning styles. With bulk communication options, teachers effortlessly disseminate announcements to entire branches, while extracurricular event integration fosters holistic development. Additionally, its testing and progress tracking capabilities empower students to advance seamlessly, supported by a dedicated college portal for administrative efficiency and data-driven decision-making.
  </Typography>
</Box>
         

        
         

        </Container>
      </Box>
      <Box sx={{background:"#181818"}} >
        <img src={GlidinCaseStudy} alt="" width="100%" />
      </Box>
    <Bgimage/>
    <Footer/>  
    </div>
    </div>
  )
}

export default Glidincasestudy
