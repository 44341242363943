import React from 'react'
import { BrowserRouter,Route, Routes } from 'react-router-dom'
import HomepageMain from './HomePage/HomepageMain'
import Ourservicessection from './Ourservicespage/Ourservicessection'
import Aboutus from './Aboutus/Aboutus'
import Portfoliopage from './PortfolioPage/Portfoliopage'
import Ourteam from './Ourteams/Ourteam'
import Contactus from './Contactus/Contactus'
import Ourblogs from './Ourblogs/Ourblogs'
import Ourblogspages from './Ourblogspages/Ourblogspages'
import Ourblogspagestwo from './Ourblogspages/Ourblogspagetwo'
import Clientspage from './Clientspage/Clientspage'
import Productbrandinginner from './Ourservicesinnerpage/Productbrandinginner'
import Uiuxinner from './Ourservicesinnerpage/Uiuxinner'
import Webdevinner from './Ourservicesinnerpage/Webdevinner'
import Appdevinner from './Ourservicesinnerpage/Appdevinner'
import Socialmediainner from './Ourservicesinnerpage/Socialmediainner'
import Productmanagementinner from './Ourservicesinnerpage/Productmanagementinner'
import Staffinginner from './Ourservicesinnerpage/Staffinginnerpage'

import Seooptimization from './Ourservicesinnerpage/Seooptimization'
import Fikaacasestudy from './Casestudies/Fikaacasestudy'
import Missiontocurecasestudy from './Casestudies/Missiontocurecasestudy'
import Idesigncasestudy from './Casestudies/Idesigncasestudy'
import Jagscasestudy from './Casestudies/Jagscasestudy'
import Mobileidlabscasestudy from './Casestudies/Mobileidlabscasestudy'
import Sfscasestudies from './Casestudies/Sfscasestudies'
import Glidincasestudies from './Casestudies/Glidincasestudy'



function Routespages() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
<Route path='/' element={<HomepageMain/>} />
<Route path='/ourservice' element={<Ourservicessection/>} />
<Route path='/portfolio' element={<Portfoliopage/>} />
<Route path='/aboutus' element={<Aboutus/>} />
<Route path='/ourteam' element={<Ourteam/>} />
<Route path='/contactus' element={<Contactus/>} />
<Route path='/blogs' element={<Ourblogs/>} />
<Route path='/blogspage' element={<Ourblogspages/>} />
<Route path='/Ourblogspagetwo' element={<Ourblogspagestwo/>}  />
<Route path='/clients' element={<Clientspage/>} />
<Route path='/productbranding' element={<Productbrandinginner/>} />
<Route path='/productmanagement' element={<Productmanagementinner/>} />
<Route path='/appdevelopment' element={<Appdevinner/>} />
<Route path='/webdevelopment' element={<Webdevinner/>} />
<Route path='/uiux' element={<Uiuxinner/>} />
<Route path='/socialmedia' element={<Socialmediainner/>} />
<Route path='/Staffing' element={<Staffinginner/>} />


<Route path='/seooptimization' element={<Seooptimization/>} />
<Route path='/fikaacasestudy'  element={<Fikaacasestudy/>} />
<Route path='/missiontocurecasestudy'  element={<Missiontocurecasestudy/>} />
<Route path='/iDesignCasestudy'  element={<Idesigncasestudy/>} />
<Route path='/Jagscasestudy' element={<Jagscasestudy/>} />
<Route path='/mobileidlabscasestudy' element={<Mobileidlabscasestudy/>} />
<Route path='/sfscasestudies' element={<Sfscasestudies/>} />
<Route path='/glidincasestudies' element={<Glidincasestudies/>} />







        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default Routespages
