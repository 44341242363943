import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MobileDev from '../../../src/Assets/Images/PortfolioImages/Rectangle 28.png'
import MobileDevTwo from '../../../src/Assets/Images/PortfolioImages/Rectangle 18.png'
import './Portfoliopage.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import FikkaThumnail from '../../../src/Assets/Images/PortfolioImages/FikkaThumnail.png'
import MissonToCureThumbnail from '../../../src/Assets/Images/PortfolioImages/MissionToCureThumbnails.png'
import JagsThumbnail from '../../../src/Assets/Images/PortfolioImages/JagsThumbnails.png'
import Idesign from '../../../src/Assets/Images/PortfolioImages/IDesignBanner.png'
import SFsbannertab from '../../../src/Assets/Images/PortfolioImages/SfsTabimage.png'
import MobileIdlab from '../../../src/Assets/Images/PortfolioImages/MID.png'
import Aagam from '../../../src/Assets/Images/PortfolioImages/Aagambanner.png'
import AngleAlign from '../../../src/Assets/Images/PortfolioImages/AngleAlignbanner.png'
import GlidinTab from '../../../src/Assets/Images/PortfolioImages/GlidinTabImage.png'
import Gptw from '../../../src/Assets/Images/PortfolioImages/selected.png'
// fikka
import Amyraa from '../../../src/Assets/Images/PortfolioImages/1.png'
import goodreg from '../../../src/Assets/Images/PortfolioImages/4.png'

import lifesupperters from '../../../src/Assets/Images/PortfolioImages/6.png'

import pesticies from '../../../src/Assets/Images/PortfolioImages/9.png'
import coloursofindia from '../../../src/Assets/Images/PortfolioImages/10.png'
import theotransfara from '../../../src/Assets/Images/PortfolioImages/11.png'
import Genwe from '../../../src/Assets/Images/PortfolioImages/12.png'
import creativewebo from '../../../src/Assets/Images/PortfolioImages/13.png'
import kiransilver from '../../../src/Assets/Images/PortfolioImages/14.png'
import greenlight from '../../../src/Assets/Images/PortfolioImages/15.png'
import LockAndKey from '../../../src/Assets/Images/PortfolioImages/16.png'
import GrandMaster from '../../../src/Assets/Images/PortfolioImages/17.png'
import Plus91 from '../../../src/Assets/Images/PortfolioImages/18.png'
import Financepandit from '../../../src/Assets/Images/PortfolioImages/19.png'
import mansamata from '../../../src/Assets/Images/PortfolioImages/20.png'
import greengati from '../../../src/Assets/Images/PortfolioImages/21.png'
import bagaria from '../../../src/Assets/Images/PortfolioImages/22.png'
import mish from '../../../src/Assets/Images/PortfolioImages/23.png'
import credence from '../../../src/Assets/Images/PortfolioImages/24.png'
import creativegarage from '../../../src/Assets/Images/PortfolioImages/25.png'
import centerec from '../../../src/Assets/Images/PortfolioImages/26.png'
import adventuregeek from '../../../src/Assets/Images/PortfolioImages/27.png'
import snekaervally from '../../../src/Assets/Images/PortfolioImages/28.png'
import Allthatthereis from '../../../src/Assets/Images/PortfolioImages/29.png'
import greenglobe from '../../../src/Assets/Images/PortfolioImages/30.png'
import weqsol from '../../../src/Assets/Images/PortfolioImages/31.png'
import sanisa from '../../../src/Assets/Images/PortfolioImages/32.png'
import praxia from '../../../src/Assets/Images/PortfolioImages/33.png'


import Airigo from '../../Assets/Images/PortfolioImages/Airigo.png'
import AsianPaints from '../../Assets/Images/PortfolioImages/AsianPaints.png'
import Sam from '../../Assets/Images/PortfolioImages/Sam.png'
import ChallengePlus from '../../Assets/Images/PortfolioImages/ChallengePlusImage.png'










// mission








export default function Portfoliotabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const images = document.querySelectorAll('.fade-in');
    images.forEach(image => {
      image.onload = () => {
        image.classList.add('loaded');
      };
    });
  }, [value]);

  return (
    <div className='portfoliotabswidth'>
      <Box sx={{ width: '100%', typography: 'body1', backgroundColor: '#181818' }}>
        <TabContext value={value}  >
          {/* <div className='Portfolio-Filter-Section'>
          <Box className="tabHeadsection" >
            <TabList onChange={handleChange} className='firsttablist' centered >
              <StyledTab  label="All" value="1" selected={value === '1'} />
              </TabList>
              <TabList onChange={handleChange} className='secondtablist' centered >
              <StyledTab label="Web Development " value="2" selected={value === '2'} />

              </TabList>
             
              <TabList onChange={handleChange} className='thirdtablist' centered >
              <StyledTab label="App Development" value="3" selected={value === '3'} />
              </TabList>
              <TabList  onChange={handleChange} className='fourthtablist' centered >
               <StyledTab label="UI / UX" value="5" selected={value === '5'} />
              </TabList>
             
              <TabList  onChange={handleChange} className='fifthtablist' centered >
              <StyledTab label="Branding" value="6" selected={value === '6'} />
              </TabList>
              <TabList  onChange={handleChange} className='sixtablist' centered >
              <StyledTab label="Social Media Marketing" value="7" selected={value === '7'} />

              </TabList>
           
            </Box>
          </div> */}
          <TabPanel value="1">
            <div className='All-Section'>
            <div className='images-section'>


            <a href="https://www.asianpaints.com/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={AsianPaints} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Asian Paints</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>

            <a href="https://www.greatplacetowork.in/for-all-community"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={Gptw} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Great Place To Work</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>

            <Link to="/fikaacasestudy" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in' >
              
               <img src={FikkaThumnail} alt="" />
               
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Fikaa</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              
               </Link>

               <a href="https://amyraa.app/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={Amyraa} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Amyraa</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>

               <a href="http://www.lifesupporters.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={lifesupperters} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Lifesupporters</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>

               <a href=""  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={goodreg} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Godrej Locks</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://www.indiapesticideslimited.com/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={pesticies} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>India pesticides limited</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
             
              
               <Link to="/missiontocurecasestudy" style={{textDecoration:"none"}}  >
               <div className='Portfolio-images-section fade-in'  >
             
               <img src={MissonToCureThumbnail} alt="" />
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Mission To Cure</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
               </Link>
              
               <Link to="/Jagscasestudy" style={{textDecoration:"none"}}  >
               <div className='Portfolio-images-section fade-in'  >
              
               <img src={JagsThumbnail} alt="" />
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Jags</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
               </Link>
              
               <Link to="/iDesignCasestudy" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={Idesign} alt=""/>
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Idesign</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
               
               </Link>


               <Link to="/mobileidlabscasestudy" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >              
                <img src={MobileIdlab} alt="" />  
                </div>
                <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Mobile Id Labs</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
               </Link>


<Link to="/sfscasestudies"  style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={SFsbannertab} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center",fontSize:"22px"}}>Surgeons For Surgeons</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>

               </Link>

               <Link to="/glidincasestudies"  style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={GlidinTab} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Glidin</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>

               </Link>

               <a href="https://aagam.org.in/home.html" target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={Aagam} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Aagam</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              </a>

              <a href="https://aagam.org.in/home.html" target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={coloursofindia} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Colours of India</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              </a>
              <a href="https://aagam.org.in/home.html" target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={theotransfara} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Theo Transfra</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              </a>
              <a href="https://genwe.today/" target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={Genwe} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>GenWe</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              </a>
              <a href="https://creativewebo.com/" target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={creativewebo} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Creative webo</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              </a>
              <a href="https://kiransilver.com/" target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={kiransilver} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Kiran Silver</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              </a>

              <a href="https://aagam.org.in/home.html" target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={greenlight} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Green light</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              </a>
               

               <a href="https://angleandalign.com/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={AngleAlign} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Angle and Align</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>


               <a href="https://lockandkeyproperties.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={LockAndKey} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Lock and key</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>

               <a href="https://www.grandmasters.co/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={GrandMaster} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Grand Master's</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>


               <a href="https://lockandkeyproperties.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={Plus91} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Plus 91</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://lockandkeyproperties.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={Financepandit} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Finance Pandit</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://www.mansamatafinance.com/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={mansamata} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Mansa Mata Finance</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
              
               <a href="https://greengati.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={greengati} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Green Gati</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://lockandkeyproperties.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={bagaria} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Bagaria & Company</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://lockandkeyproperties.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={mish} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Mish</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://lockandkeyproperties.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={credence} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Credence Consultants</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://creative-garage.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={creativegarage} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Creative Garage</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://sanisa.org/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={sanisa} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Sa Ni Sa</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://lockandkeyproperties.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={weqsol} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Weqsol</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://lockandkeyproperties.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={praxia} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Praxia</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://lockandkeyproperties.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={snekaervally} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>The Sneaker Valley</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://lockandkeyproperties.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={Allthatthereis} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>All That There Is</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>
               <a href="https://www.greenglobe.world/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={greenglobe} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Greenglobe Fuel Solutions</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>


               <a href="https://play.google.com/store/apps/details?id=app.sam&hl=en_IN"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={Sam} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Aadinath Exports</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>

               
               <a href="https://airigo.in/"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={Airigo} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Airigo</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>

               <a href="https://play.google.com/store/apps/details?id=com.challengeplus&hl=en_IN"  target="_blank" style={{textDecoration:"none"}} >
               <div className='Portfolio-images-section fade-in'  >
               <img src={ChallengePlus} alt="" />
             
               </div>
               <div style={{display:"flex",alignItems:'center',justifyContent:"space-between",marginTop:"6%",marginBottom:"10%"}} >
               <div>
               <p className='fade-in' style={{display:"flex",alignItems:"center"}}>Challenge Plus</p>
               </div>
               <div  className='fade-in' style={{
              width:"40px",
               height:"40px",
               border:"1px solid white",
               borderRadius:"50%",
               display:"flex",
               alignItems:"center",
               justifyContent:"center",
               color:"white"}} >
               <i class="ri-arrow-right-up-line"></i>
               </div>
               </div>
              

               </a>

              
             

               

               </div>
            </div>
        </TabPanel>
        <TabPanel value="2">
        <div className='All-Section'>
        <div className='images-section'>
        <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""/>
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""    />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""    />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               </div>
            </div>
        </TabPanel>
        <TabPanel value="3">
        <div className='All-Section'>
        <div className='images-section'>
        <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt="" />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""  />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""  />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""  />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""    />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               </div>
            </div>
        </TabPanel>
        <TabPanel value="4">
        <div className='All-Section'>
               <div className='images-section'>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""  />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""  />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""  />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""    />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               </div>
            </div>
        </TabPanel>
        <TabPanel value="5">
        <div className='All-Section'>
        <div className='images-section'>
        <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""  />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""  />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""    />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               </div>
            </div>
        </TabPanel>
        <TabPanel value="6">
        <div className='All-Section'>
        <div className='images-section'>
        <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt="" />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""/>
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt="" />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt="" />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""    />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               </div>
            </div>
        </TabPanel>
        <TabPanel value="7">
        <div className='All-Section'>
        <div className='images-section'>
        <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""  />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""    />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDevTwo} alt=""    />
               <p>Mobile ID Labs</p>
               </div>
               <div className='Portfolio-images-section fade-in'  >
               <img src={MobileDev} alt=""   />
               <p>Mobile ID Labs</p>
               </div>
               </div>
            </div>
        </TabPanel>

          {/* Rest of the TabPanel components */}
        </TabContext>
      </Box>
      <div className='portfoliotabsbottom'></div>
    </div>
  );
}

// Custom styled Tab component
// Custom styled Tab component
// Custom styled Tab component
const StyledTab = ({ selected, ...props }) => (
  <Tab
    {...props}
    sx={{
      fontFamily: "DM Sans18ptLight",
      fontSize: "21px",
      fontWeight: "400",
      color:selected ? 'rgba(227, 142, 0, 1)' : 'white',
      background: selected ? 'white' : 'none',
    }}
  />
);

