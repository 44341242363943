import React from 'react'
import './Clientreview.css'
import userImageOne from '../../../Assets/Images/Ellipse 7.png';
import userImagetwo from '../../../Assets/Images/Clientsreview/Userimagetwo.png'
import userImagethree from '../../../Assets/Images/Clientsreview/Userimagethree.png'
import AirigoImg from '../../../Assets/Images/Clientsreview/AirigoImgIcon.png'


function Clientreview() {
  return (
    <div className='Clientreview' >
      <div className='reviewleft' >
     <div className='reviewleft-firstbox'>
        <h6>SINCE FROM 2019</h6>
        <h2>Keep pushing forward We've got your back.</h2>
        <p>Things go wrong have questions. We’ve understand. So we have people</p>
     </div>
     <div className='line-inside-review'></div>
     <div className='reviewleft-secondbox' >
      <div className='tenkuser' >
      <h1><span style={{marginRight:"10px"}}>100k</span>+</h1>
<p>Happy Users</p>
      </div>
      <div className='hundredproject' >
      <h1><span style={{marginRight:"10px"}}>100</span>+</h1>
<p>Projects Already Done</p>
      </div>
     </div>
      </div>
      <div className='reviewright' >
        <div className="reviewscollar ">
           <div className="reviews">
           <div className='reviews-para'>
           <div>
           <p>FIKAA - BE Financially Free lauds D-WebBox as transformative partners, revolutionizing their tech journey with responsive, tailor-made solutions. From overcoming hurdles to proactive problem-solving, D-WebBox's dedication has fostered collaboration and tangibly improved operations, making them an indispensable asset in FIKAA's success story.</p>
           </div>
             
            <div className='ratings'>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>

           </div>
         
           </div>
          
           <div className='user-reviews' >
             <div>
              <img src={userImagethree} alt="" />
             </div>
             <div>
             <h6>Harsh Choksey</h6>
             <p>Co-Founder & CEO, FIKAA</p>
             </div>
           </div>
           </div>
           <div className="reviews">
           <div className='reviews-para'>
           <div>
           <p>DWEBBOX did an outstanding job creating my website for Airigo Multiventures Private Limited. From the start, their team was professional, attentive, and incredibly skilled. They listened to my ideas and turned them into a beautiful, functional site that exceeded my expectations. Their dedication to quality and customer satisfaction was evident throughout the entire process. I highly recommend DWEBBOX for anyone looking for top-notch web development services to bring their vision to life and increase presence on the web.</p>
           </div>
             
            <div className='ratings'>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>

           </div>
         
           </div>
          
           <div className='user-reviews' >
             <div>
              <img src={AirigoImg} alt="" />
             </div>
             <div>
             <h6>AIRIGO</h6>
             {/* <p>Co-Founder & CEO, FIKAA</p> */}
             </div>
           </div>
           </div>
           <div className="reviews">
           <div className='reviews-para'>
           <div>
           <p>FIKAA - BE Financially Free lauds D-WebBox as transformative partners, revolutionizing their tech journey with responsive, tailor-made solutions. From overcoming hurdles to proactive problem-solving, D-WebBox's dedication has fostered collaboration and tangibly improved operations, making them an indispensable asset in FIKAA's success story.</p>
           </div>
             
            <div className='ratings'>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>

           </div>
         
           </div>
          
           <div className='user-reviews' >
             <div>
              <img src={userImagethree} alt="" />
             </div>
             <div>
             <h6>Harsh Choksey</h6>
             <p>Co-Founder & CEO, FIKAA</p>
             </div>
           </div>
           </div>
           <div className="reviews">
           <div className='reviews-para'>
           <div>
           <p>FIKAA - BE Financially Free lauds D-WebBox as transformative partners, revolutionizing their tech journey with responsive, tailor-made solutions. From overcoming hurdles to proactive problem-solving, D-WebBox's dedication has fostered collaboration and tangibly improved operations, making them an indispensable asset in FIKAA's success story.</p>
           </div>
             
            <div className='ratings'>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>

           </div>
         
           </div>
          
           <div className='user-reviews' >
             <div>
              <img src={userImagethree} alt="" />
             </div>
             <div>
             <h6>Harsh Choksey</h6>
             <p>Co-Founder & CEO, FIKAA</p>
             </div>
           </div>
           </div>
           <div className="reviews">
           <div className='reviews-para'>
           <div>
           <p> DWEBBOX did an outstanding job creating my website for Airigo Multiventures Private Limited. From the start, their team was professional, attentive, and incredibly skilled. They listened to my ideas and turned them into a beautiful, functional site that exceeded my expectations. Their dedication to quality and customer satisfaction was evident throughout the entire process.</p>
           </div>
             
            <div className='ratings'>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>

           </div>
         
           </div>
          
           <div className='user-reviews' >
             <div>
              <img src={AirigoImg} alt="" />
             </div>
             <div>
             <h6>Airigo</h6>
             {/* <p>Founder & CEO, GenWE Inc.</p> */}
             </div>
           </div>
           </div>
           <div className="reviews">
           <div className='reviews-para'>
           <div>
           <p>FIKAA - BE Financially Free lauds D-WebBox as transformative partners, revolutionizing their tech journey with responsive, tailor-made solutions. From overcoming hurdles to proactive problem-solving, D-WebBox's dedication has fostered collaboration and tangibly improved operations, making them an indispensable asset in FIKAA's success story.</p>
           </div>
             
            <div className='ratings'>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>

           </div>
         
           </div>
          
           <div className='user-reviews' >
             <div>
              <img src={userImagethree} alt="" />
             </div>
             <div>
             <h6>Harsh Choksey</h6>
             <p>Co-Founder & CEO, FIKAA</p>
             </div>
           </div>
           </div>
           <div className="reviews">
           <div className='reviews-para'>
           <div>
           <p>As the founder of GenWE, I've been delighted with our 2-year partnership with D-WebBox. They embrace each project as their own, consistently going above and beyond, such as tackling intricate challenges like teenage identity verification. With their focus on value, solution-driven approach, and commitment to true partnership, D-WebBox epitomizes excellence in every aspect.</p>
           </div>
             
            <div className='ratings'>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
  <path fill="#FFD700" d="M12 2 l2.3 7.0 h7.1l-5.7 4.1 2.2 6.8-5.9-4.3-5.9 4.3 2.2-6.8-5.7-4.1h7.1z"/>
</svg>

           </div>
         
           </div>
          
           <div className='user-reviews' >
             <div>
              <img src={userImagetwo} alt="" />
             </div>
             <div>
             <h6>Pavan Bhatia</h6>
             <p>Founder & CEO, GenWE Inc.</p>
             </div>
           </div>
           </div>
        </div>
      </div>
    </div>
  )
}

export default Clientreview
