import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Bgimage from '../HomePage/Bgimagesection/Bgimage';
import { Box, Container, Typography } from '@mui/material';
import SfsCaseStudy from '../../../src/Assets/Images/PortfolioImages/mtc_1x.webp'
import SfsCaseStudyBanner from '../../../src/Assets/Images/PortfolioImages/sfsbanner.webp'


function Sfscasestudies() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
      }, []);

  return (
    <div style={{background:"#181818"}} >
    <Navbar/>
    <Box sx={{ width: "100%", background: "#181818" }} >
        <Container component="main" sx={{ pt: 10, 
        background: "#181818", 
         '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
         paddingTop:"40px",
              },}}>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",
          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '18px',
              },
          }} >
            <span
              style={{
                content: "''",
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                background: "white",
                marginRight:"5px",
              }}
            ></span> Portfolio {/* Add a span with the bullet class */}
          </Typography>
          <Typography variant="h1"
           sx={{ color: "#ffffff", 
          display: 'flex', 
          alignItems: 'center',
          fontSize:"70px",
          fontFamily:"Epilogue",
          fontWeight:"600" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '24px',
              },
          }}>
         Surgeons For Surgeons
          </Typography>
        </Container>
    </Box>
    <Box sx={{ width: "100%", background: "#181818" }} >
        <Container  component="main" sx={{ 
          pt: 10,
        pb:10, 
        background: "#181818",
        '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            pt:5,
            pb:5,
         }
        
        }}>
        <Box>
            <img src={SfsCaseStudyBanner} width= "100%" alt="" />
          </Box>
         
         <Box sx={{display:"flex", 
         alignItems:"center",
          width:"100%",
          background:"rgba(34, 34, 34, 1)",
          height:"20vh",
          marginTop:"20vh",
          '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          flexDirection:"column",
                          height:"30vh",
                          marginTop:"5vh",
                          
              },
          
          }} >
           <Box sx={{
            display:"flex",
           flexDirection:"column",
           alignItems:"center",
            width:"30%",
            color:"rgba(255, 255, 255, 1)",
            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
            width:"60%",
            marginTop:"5%",
            height:"33%"

                          
              },
            
            }} >
           <Typography variant='h5'sx={{
                        fontFamily: "DM Sans18ptLight",

             '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"16px",
                          
              },
          }} >
         Industry
          </Typography>
          <Typography paragraph sx={{fontWeight:"300",fontSize:"20px",
                      fontFamily: "DM Sans18ptLight",

            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
         Healthcare
          </Typography>
           </Box>
           <Box sx={{display:"flex",
           flexDirection:"column",
           alignItems:"center",
            width:"40%",
            color:"rgba(255, 255, 255, 1)",
            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          width:"60%",
                          
              },
            
            }} >
          <Typography variant='h5'sx={{
                        fontFamily: "DM Sans18ptLight",

             '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
          Services Provided
          </Typography>
          <Typography paragraph sx={{fontWeight:"300",fontSize:"20px",
                      fontFamily: "DM Sans18ptLight",

            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
          Web & App Development
          </Typography>
           </Box>
           <Box sx={{display:"flex",flexDirection:"column",alignItems:"center", width:"30%",color:"rgba(255, 255, 255, 1)"}} >
           <Typography variant='h5'sx={{
                        fontFamily: "DM Sans18ptLight",

             '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"14px",
                          
              },
          }} >
          Year
          </Typography>
          <Typography paragraph sx={{fontWeight:"300",fontSize:"20px",
                      fontFamily: "DM Sans18ptLight",

            '@media (max-width:700px)': { // Styles for screens with a maximum width of 600px (mobile view)
                          fontSize:"12px",
                          
              },
          }} >
          2022-2023
          </Typography>
           </Box>
         </Box>
         
<Box sx={{
  background:"rgba(34, 34, 34, 1)",
padding:"5vh",
marginTop:"10vh",
'@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
  marginTop:"5vh",
  padding:"3vh",

              },

}} >
<Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
The Challenge        
  </Typography>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
The problem of verifying doctors on the platform. We need to create an extensive verification form/method to ensure that only genuine doctors can register and are onboarded on the platform.
How do we foster interaction between doctors based on the details that they enter when they register on the platform?
Confidentiality of the data (videos, images) shared on the platform.
Can we create more engagement by adding features such as Job opportunities and a user-generated content feed for doctors to encourage them to invest in the platform?
Can we create a feature that will help doctors post any emergency or crisis they face so that they can get help from other doctors of the same speciality on the platform?
Can we create a feature that will help doctors obtain information to expand their knowledge of their current/other fields? create solution for this problem         </Typography>
</Box>
          
          <Box sx={{
            background:"rgba(34, 34, 34, 1)",
          padding:"5vh",
          marginTop:"10vh",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
            padding:"3vh",
            marginTop:"5vh",


          }
          }} >
<Typography variant='h3' 
          sx={{ 
          color: "#ffffff",
          fontSize:"24px",
          marginTop:"5vh",
          fontWeight:"600",
          marginBottom:"3vh",
          fontFamily:"Epilogue",
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '20px',
              },
          }} >
Approach
  </Typography>
          <Typography paragraph 
          sx={{ 
          color: "#ffffff",
          fontSize:"20px",

          fontFamily:"DM Sans_18pt-ExtraLight" ,
          '@media (max-width:600px)': { // Styles for screens with a maximum width of 600px (mobile view)
                fontSize: '12px',
              },
          }} >
The platform ensures a smooth but rigorous verification of doctors through extensive documentation and cross-referencing, fostering genuine interactions based on shared interests and expertise. Confidentiality is prioritized with end-to-end encryption and strict access controls for all shared data. Engaging features such as a job board and user-generated content feed encourage investment in the platform, while emergency support features enable quick assistance within the medical community. Additionally, knowledge expansion tools provide curated resources and opportunities for continuous learning and professional development.
  </Typography>
</Box>
         

        
         

        </Container>
      </Box>
      <Box sx={{background:"#181818"}} >
        <img src={SfsCaseStudy} alt="" width="100%" />
      </Box>
    <Bgimage/>
    <Footer/>  
    </div>
  )
}

export default Sfscasestudies
